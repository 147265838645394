@font-face {
    font-family: "chalk";
    src: url("../fonts/edeka_chalk.woff") format("woff");
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("../fonts/RobotoCondensed/RobotoCondensed-Regular.ttf");
    src: url("../fonts/RobotoCondensed/RobotoCondensed-Bold.ttf");
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Regular.ttf");
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #000;
    background-image: url("../img/background.jpg");
    background-repeat: repeat;
    background-size: 600px;
    margin: 0;
    font-family: "Roboto";
}